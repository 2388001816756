.bpro3{
    margin: 2vw 0;
    display: flex;
    justify-content: space-between;
}
.bpro4{
    width: 60%;
    display:grid;
    grid-template-columns: auto;
    row-gap: 2vw;
}
.bpro4a{
    
    width: 37%; 
    margin-left: 3%;
    display: grid;
    grid-template-columns: auto;
    row-gap: 1vw;

}
.gfctyhgc{
  
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
}
.uytghfyvj{
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
}
.bpro5{
     border: 1px solid #061F14;
     height: 10.5vw;
     padding: 1vw;
     display: grid;
     grid-template-columns:  auto;
     column-gap: 1VW;
}


.bpro51{
    width: 13vw;
    /* border: 1px solid red; */
    /* height: 13vw; */

}
.bpro51 img{
    width: 100%;
}

.bpro52a{
    font-size: 1.6vw;
    line-height: 1.6vw;
    font-family: blk300;
    font-weight: 400;
    letter-spacing: 1%;
}
.bpro52b{
    font-family:axi400;
    font-size: 0.7vw;
    line-height: 0.7vw;
    font-weight: 400;
    margin-top: 0.5vw;
}
.bpro52c{
    font-family:axi400;
    font-size: 0.8vw;
    line-height: 1.2vw;
    font-weight: 400;
    margin-top: 0.5vw;

}
.bpro52d{
    margin-top: 0.5vw;

    font-family:axi400;
    font-size: 0.8vw;
    line-height: 0.8vw;
    font-weight: 600;
    text-decoration: underline;
}
.bpro52e{
    margin-top: 0.5vw;

    display: flex;
    flex-direction:row;
   justify-content: space-between;
}
.bpro52g{
    font-family:axi400;
    font-size: 0.8vw;
    line-height: 1vw;
    font-weight: 600;

}
.bpro52h{
    font-family:axi400;
    font-size: 0.65vw;
    line-height: 0.65vw;
    font-weight: 400;
    margin-top: 0.3vw;

}
.bpro5i{
    display: flex;
    justify-content: space-between;
    /* visibility: hidden; */
    /* border: 1px solid red; */
}
.bpro5i button{
   background-color: #B0714E;
   color: #E6DED1;
   font-family:axi400;
   font-size: 1vw;
   line-height:1vw;
   font-weight: 400;
   width: 13.3vw;
   height: 3.1vw;
   border: none;
   outline: none;
   /* margin-left: auto; */
   border-radius: 3px;
}
.bprohr1{
    margin:1vw 0;
}

@media(Max-width:600px){
    .bpro3{
        margin: 0  0 15% 0;
        display: flex;
        flex-direction: column;
    }
    .bpro4{
        width: 100%;
        display:grid;
        grid-template-columns: auto;
        /* border: 1px solid red; */
        row-gap: 3%;
    }
    .bpro4a{
        
        width: 100%;
        margin-left: 0; 
        margin-top:18%;
        display: grid;
        grid-template-columns: auto;
        row-gap: 1vw;
    
    }
    .bpro5{
        border: 1px solid #061F14;
        padding: 4%;
        height: 100%;
        display: flex;
        flex-direction: column;
        column-gap: 1vw;
   }
   
.bpro51{
    width: 100%;
    height:100%;

}
.bpro51 img{
    width: 100%;
}
.bpro52{
    margin:5%  0;
}
.bpro52a{
    font-size: 24px;
    line-height: 24px;
    font-family: blk300;
    font-weight: 400;
    letter-spacing: 1%;
}
.bpro52b{
    font-family:axi400;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 3%;
}
.bpro52c{
    font-family:axi400;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    margin-top: 3%;

}
.bpro52d{
    margin-top: 3%;

    font-family:axi400;
    font-size:14px;
    line-height: 14px;
    font-weight: 600;
    text-decoration: underline;
}
.bpro52e{
    margin-top: 3%;

    display: flex;
    flex-direction:row;
   justify-content: space-between;
}
.bpro52g{
    font-family:axi400;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    

}
.bpro52h{
    font-family:axi400;
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    margin-top: 0.3vw;

}
.bpro5i button{
    background-color: #B0714E;
    color: #E6DED1;
    font-family:axi400;
    font-size: 14px;
    line-height:14px;
    font-weight: 400;
    width: 120px;
    height: 45px;
    border: none;
    outline: none;
    border-radius: 3px;
 }
 .bprohr1{
     margin:3% 0;
 }
 .ourfoot1{
    display: none;
 }
}
