#bar1{
    display: none;
}
@media (max-width:601px) {
    #bar1{
        display:block;
        background-color: #1e1e1e;

        width: 100%;
        height: 94vh;
       
    }
    .bar2{
        display: flex;
        flex-direction: column;
        
       width: 90%;
       margin-left: 5%;
       margin-top: 5%;
    }
    .bar3{
        width: 100%;
        margin-top: 3%;
        text-align: end;
    }
    .bar3 img{
        width: 20px;
        color: aliceblue;
    }
    .bar4{
        margin-top: 20%;
    }
    .bar2 a{
        color: #d9d9d9;
        font-size: 20px;
        font-family: axi400;
        font-weight: 400;
        font-style: normal;
        text-decoration: none;
    }
   
    #bar5{
        margin-top: 5%;
    }
    .bar9{
        margin-top: 5%;
        opacity: 0.2;
    }
    

    .sidebar-container{
        color: white;
            position: fixed;
        left: 0;
        
        right: 0;
        top: 0;
        bottom: 0;
            z-index: 999;
            width: 70%;
            height: 100%;
            background:#1E1E1E;
          
            align-items: center;
    
            transition: 0.5s ease-in-out;
        }
        
        
        
       
        .opacity-on{
            
            width: 100%;
            opacity: 1;
            left: 0;
        }
      
      
        .opacity-off{
            opacity: 0;
            left: 100%;
        }
        .sidebar-menu hr{
            color: #F5F5F5;
        }
      
            
}



    