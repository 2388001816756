.foot{
  border: 1px solid #FFF2E2 ;
  height: 10%;
  border-left: none;
  border-right: none;
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.foot1{
   display: flex;
   justify-content: center;
   align-items: center;
 
   height: 10%;
   width: 94%;
   /* margin-left: 3%; */
  /* border: 1px solid rgb(0, 255, 55); */


}
.foot2{
   width: 40%;
   display: flex;
   justify-content: space-between;
    /* border: 1px solid rgb(0, 255, 55); */
  }
  .foot2a{
    color:white;
    font-size: 0.9vw;
    line-height: 0.9vw;
    font-weight: 400;
    font-family:axi400;

  }
  .foot2ab{
    color:#1E1E1E;
    font-size: 0.9vw;
    line-height: 0.9vw;
    font-weight: 400;
    font-family:axi400;

  }
  .foot3{
    width: 60%;
   display: flex;
   column-gap: 3.5%;
   color: #FFF2E2;
   justify-content: end;
    /* border: 1px solid rgb(0, 255, 55); */
  }
  .foot13ab a{
    color: #FFF2E2;
  }
 @media (max-width:756px) {
  
  .foot2a{
    font-size: 14px;
    line-height: 14px;

  }
  .foot2ab{
   font-size: 14px;
   line-height: 14px;

  }
 }
