*{
  padding: 0;
  margin: 0;
  text-decoration: none;
}

@font-face{
  font-family:axi400;
  src:url( ".../../../public/Fonts/Axiforma/Axiforma/Axiforma-Light.woff");
}
@font-face{
  font-family:axi500;
  src:url( ".../../../public/Fonts/Axiforma/Axiforma/Axiforma-Regular.woff");
}

@font-face{
  font-family:blk300;
  src:url( ".../../../public/Fonts/Blacklist/Blacklist-Light.woff");
}
@font-face{
  font-family:blk400;
  src:url( ".../../../public/Fonts/Blacklist/Blacklist-Regular.ttf");
}
a{
  text-decoration: none;
}
.productitteams{
  /* border: 1px solid red; */
}

.innerproductiteam{
  /* border: 1px solid red; */
  display: flex;
  padding-top: 3vw;
  padding-bottom: 5vw;
  /* height: 10vh; */
}
.ytdfuyt6fu{
  width: 29vw;
}

input:focus{
  outline: none;
}
.ujhgikv{
 margin-right: 5px;
}
.yurft768uyg7ui{
  width: 25%;
  /* border: 1px solid red; */
}
.uytf76rf7uy{
  width: 66%;
}
.yutfu67iyhi{
  font-family: axi400;
  font-size: 1.04vw;
  width: 100%;
  /* padding-top: 10vw; */
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
}
.yguftyitu{
  font-family: axi400;
  font-size: 1.04vw; 
  color: #141414;
}
.hgfyvutu{
  font-family: axi400;
  font-size: 1.04vw; 
  color: #141414;
  padding-top: 2vw;
}
.giuytgf76iuy{
  font-family: blk500;
  font-size: 1.5vw;
  color: #141414;
  padding-top: 0.5vw;
}
.iuyfg7tuy{
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* padding: 2vw 0; */
  padding-top: 3vw;
  padding-bottom: 1.5vw;
  font-size: 3.1vw;
  font-family: blk400;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
}
.jufgtubook84{
  cursor: pointer;
}
.uytfdr5rtfdyt{
  background-color: #485647;
 /* padding: 10px; */
 color: white;
 padding: 1.5px 5px;
 cursor: pointer;
text-align: center;
margin: 0 5px;
}
.yutrfi{
  text-decoration: underline;
}
.iuyf7iyug{
  padding-top: 2vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 2vw;
  /* border: 1px solid red; */
}
.iuyfgt6ty{
  color: white;
}
.iuyfgt6ty:hover{
  color: #141414;
}
.uytdf6truyfyt h2{
  /* color: red; */
  padding: 0.5vw 0;
  font-family: axi500;
  font-size: 1.17vw;
  line-height: 1.17vw;
}
.uytdf6truyfyt li{
  /* color: red; */
  font-family: axi400;
  font-size: 1.04vw;
  /* line-height: 1.04vw; */
}
.uytdf6truyfyt p{
  /* color: red; */
  font-family: axi400;
  font-size: 1.04vw;
  /* line-height: 1.04vw; */
}
.uygfui7ug{
  cursor: pointer;
}
.uytr8767{
  /* padding-top: 2vw; */
  font-size: 1.6vw;
  font-family: axi400;
}
.uytr8767 input{
  border: none;
  /* font-size: 1.6vw; */
  cursor: pointer;
}
.uytr8767 input:focus{
  outline: none;
}
.yd6try{
  cursor: pointer;
}
@media (max-width:768px) {
  .yurft768uyg7ui{
  display: none;
    /* border: 1px solid red; */
  }
  .uytf76rf7uy{
    width: 100%;
  }
  .uytr8767{
    font-size: 16PX;
  }
  .innerproductiteam{
    /* border: 1px solid red; */
    display: inline;
    /* height: 10vh; */
  }
  .yutfu67iyhi{
   font-size: 16px;
   padding-top: 10vw;
  }
  .giuytgf76iuy{
    font-size: 24px;
  }
  .yguftyitu{
    font-size: 16px;
  }
  .ytdfuyt6fu{
    width: 100%;
  }
  .innerproductiteam{
    padding-bottom: 15vh;
  }
  .iuyf7iyug{
    padding-bottom: 9vh;
    margin-bottom: 1vh;
    row-gap: 4.5vh;
    border-bottom: 1px solid #141414;
  }
  .hgfyvutu{
    font-size: 12px;
    line-height: 14px;
  }
  .iuyfg7tuy{
    font-size: 32px;
  }
  .uytdf6truyfyt h2{
    /* color: red; */
   font-size: 18px;
   padding: 20px 0;
  }
  .uytdf6truyfyt li{
 font-size: 16px;
  }
  .uytdf6truyfyt p{
  font-size: 16px;
  }
}
